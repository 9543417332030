import React from 'react'
import createGlobe from 'cobe'
import {useEffect, useRef} from 'react'

// https://github.com/shuding/cobe

const size = 200
export default function Loading() {
  const canvasRef = useRef()

  useEffect(() => {
    let phi = 0

    const globe = createGlobe(canvasRef.current, {
      devicePixelRatio: 2,
      width: size * 2,
      height: size * 2,
      phi: 0,
      theta: 0,
      dark: 1,
      diffuse: 1.2,
      mapSamples: 16000,
      mapBrightness: 6,
      baseColor: [0.3, 0.3, 0.3],
      markerColor: [0.1, 0.8, 1],
      glowColor: [1, 1, 1],
      markers: [
        // longitude latitude
        // {location: [37.7595, -122.4367], size: 0.03},
        // {location: [40.7128, -74.006], size: 0.1},
      ],
      onRender: (state) => {
        // Called on every animation frame.
        // `state` will be an empty object, return updated params.
        state.phi = phi
        phi += 0.01
      },
    })

    return () => {
      globe.destroy()
    }
  }, [])

  return (
    <div
      style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}
    >
      <canvas
        ref={canvasRef}
        style={{width: size, height: size, maxWidth: '100%', aspectRatio: 1}}
      />
    </div>
  )
}
