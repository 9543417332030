import React, {useState, useEffect, useReducer} from 'react'
import * as ReactDOM from 'react-dom/client'
import {GraphiQL} from 'graphiql'
import {createGraphiQLFetcher} from '@graphiql/toolkit'
import {useExplorerPlugin} from '@graphiql/plugin-explorer'
// import Transactions from './Transactions.gql'
import Loading from './Loading'
import {newFetch} from './utils'

const endpoints: {[key: string]: string} = {
  MAINNET: 'mainnet.hedera.api.hgraph.dev/v1/graphql',
  TESTNET: 'testnet.hedera.api.hgraph.dev/v1/graphql',
}

const createFetcher = (token: string) => {
  const network = localStorage.getItem('network') || 'MAINNET'
  return createGraphiQLFetcher({
    url: `https://${endpoints[network]}`,
    subscriptionUrl: `wss://${endpoints[network]}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    wsConnectionParams: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },

    fetch: newFetch,
  })
}

// example for inspiration: https://github.com/dotansimha/graphql-yoga/blob/main/packages/graphiql/src/YogaGraphiQL.tsx
function Editor() {
  const [token, setToken]: [any, any] = useState()
  const [query, setQuery] = useState('')
  const [schema, setSchema] = useState('')
  const [network, setNetwork] = useState(localStorage.getItem('network') || 'MAINNET')
  const [_, forceUpdate] = useReducer((x) => x + 1, 0)

  const explorerPlugin = useExplorerPlugin({
    query,
    onEdit: setQuery,
  })

  // authentication
  useEffect(() => {
    async function handleAuthentication() {
      //@ts-ignore
      const token = await window?.auth0?.getTokenSilently()
      if (token) setToken(token)
      //@ts-ignore
      else
        window.addEventListener('userDidAuthenticate', ({detail: token}) => {
          setToken(token)
        })
    }
    handleAuthentication()

    // force state update when switching between mainnet and testnet
    window.addEventListener('networkDidChange', () => {
      forceUpdate()
      setNetwork(localStorage.getItem('network') || 'MAINNET')
    })
  }, [])

  // fetch schema
  useEffect(() => {
    if (!token || !network) return
    async function fetchSchema() {
      const network = localStorage.getItem('network') || 'MAINNET'
      const response = await fetch(`https://${endpoints[network]}`, {
        method: 'POST',
        headers: {Authorization: `Bearer ${token}`},
        body: JSON.stringify({
          operationName: 'IntrospectionQuery',
          query:
            '\n    query IntrospectionQuery {\n      __schema {\n        \n        queryType { name }\n        mutationType { name }\n        subscriptionType { name }\n        types {\n          ...FullType\n        }\n        directives {\n          name\n          description\n          \n          locations\n          args {\n            ...InputValue\n          }\n        }\n      }\n    }\n\n    fragment FullType on __Type {\n      kind\n      name\n      description\n      \n      fields(includeDeprecated: true) {\n        name\n        description\n        args {\n          ...InputValue\n        }\n        type {\n          ...TypeRef\n        }\n        isDeprecated\n        deprecationReason\n      }\n      inputFields {\n        ...InputValue\n      }\n      interfaces {\n        ...TypeRef\n      }\n      enumValues(includeDeprecated: true) {\n        name\n        description\n        isDeprecated\n        deprecationReason\n      }\n      possibleTypes {\n        ...TypeRef\n      }\n    }\n\n    fragment InputValue on __InputValue {\n      name\n      description\n      type { ...TypeRef }\n      defaultValue\n      \n      \n    }\n\n    fragment TypeRef on __Type {\n      kind\n      name\n      ofType {\n        kind\n        name\n        ofType {\n          kind\n          name\n          ofType {\n            kind\n            name\n            ofType {\n              kind\n              name\n              ofType {\n                kind\n                name\n                ofType {\n                  kind\n                  name\n                  ofType {\n                    kind\n                    name\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  ',
        }),
      })
      const json = await response.json()
      if (json.data) setSchema(json.data)
    }
    fetchSchema()
  }, [token, network])

  if (!token || !schema) return <Loading />
  // if (true) return <Loading />
  //TODO stop re-rendering on query change, would also make it uncessary to manually fetch schema
  return (
    <GraphiQL
      fetcher={createFetcher(token)}
      query={query}
      //@ts-ignore
      schema={schema}
      onEditQuery={setQuery}
      plugins={[explorerPlugin]}
    >
      <GraphiQL.Logo>
        <a href='https://hgraph.io' target='_blank'>
          <img alt='logo' src='/assets/logo-rainbow.png' width='100' />
        </a>
      </GraphiQL.Logo>
    </GraphiQL>
  )
}

const root = ReactDOM.createRoot(document.getElementById('graphiql-react-root') as HTMLElement)

root.render(<Editor />)
