const {parse} = require('json-bigint')({
  useNativeBigInt: false,
  // alwaysParseAsBig: true,
})

/*
 * These are utilities to patch the troubles with rounding large integers from json in javascript
 */

BigInt.prototype.toJSON = function () {
  if (Number.MIN_SAFE_INTEGER < this && this < Number.MAX_SAFE_INTEGER) return Number(this)
  else return this.toString()
}

JSON.parse = (text, reviver) => {
  return parse(text)
}

// Fixes simple fetch
export const newFetch = async (...args) => {
  const response = await window.fetch(...args)
  const json = parse(await response.text())

  response.json = async () => json

  return response
}
